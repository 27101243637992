import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { UnAuthRedirect } from '@uz/unitz-components-web/AppRedirect';
import BillingStats from '@uz/unitz-tool-components/B2BBilling/BillingStats';
import BillingCurrentPlan from '@uz/unitz-tool-components/B2BBilling/CurrentPlan';

const Index = () => {
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="p-4 mx-auto max-w-screen-lg" style={{ maxWidth: 1024 }}>
          {/* <div className="mb-3 text-main uppercase font-semibold text-base">
            {ctx.apply('i18n.rt', 'Payment Information')}
          </div> */}
          <BillingStats />
          <BillingCurrentPlan />
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default Index;
