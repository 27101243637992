import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Row, Col, Card } from 'antd';
import _ from 'lodash';
import LoadingCheckWrapper from '@uz/unitz-components-web/LoadingCheckWrapper';
import gstyles from '@vl/gstyles';
import Link from '@uz/unitz-components-web/Link';
import Segment from '@uz/unitz-components-web/Segment';

const BillingStats = () => {
  return (
    <LoadingCheckWrapper>
      <DIV className="component">
        <DIV forceCtx>
          <div className="px-2 py-1">
            <Segment title={ctx.apply('i18n.rt', 'Current plan')}>
              <Card
                size="small"
                title={ctx.apply('i18n.rt', `Unitz ${ctx.get('billing.subscription.name')}`)}
                actions={[
                  <div key="action" className='flex justify-between px-3'>
                    <div className="">
                      <strong className="font-bold pr-1">
                        {`${ctx.apply('i18n.l', 'currency', ctx.get('billing.subscription.price') || 0)}`}
                      </strong>
                      {`${ctx.apply('i18n.rt', 'per month')}`}
                    </div>
                    <div className="font-bold">
                      {`${ctx.apply('i18n.l', 'currency', ctx.get('billing.subscription.price') || 0)}`}
                    </div>
                  </div>,
                ]}
                extra={
                  <Link to={ctx.apply('routeStore.toUrl', 'toolAccountSettingsBillingPlans')}>
                    <div>{ctx.apply('i18n.rt', 'Change')}</div>
                  </Link>
                }
              >
                <div className="text-gray-500 mb-2">
                  {ctx.apply('i18n.rt', 'Next payment due')}
                </div>
                <div className="text-gray-500 mb-2">
                  {ctx.apply('i18n.rt', 'Next payment due')}
                </div>
                <div className="text-gray-500 mb-2">
                  {ctx.apply('i18n.rt', 'Next payment due')}
                </div>
              </Card>
            </Segment>
          </div>
        </DIV>
      </DIV>
    </LoadingCheckWrapper>
  );
};

export default displayName(BillingStats);
