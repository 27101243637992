import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRouteDe';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import { notification } from 'antd';
import countryList from 'country-list';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import useLoadingModel from '@vl/hooks/useLoadingModel';

const bindData = bindings({
  loader: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchData: true }))),
            billing: hook((ctx) => {
              const account_id = ctx.apply('accountModel.getAccountId');
              const condition = {
                where: {
                  account_id: { _eq: account_id },
                },
              };
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!account_id) return null;
                    const client = await getClient();
                    const resData = await client.request(
                      gql`
                        query b2b_account_billing($where: b2b_account_billing_bool_exp = {}) {
                          b2b_account_billing(where: $where) {
                            id
                            information
                            payment_method
                            subscription {
                              id
                              plan_id
                              price
                              currency_iso_code
                              name
                              description: name
                            }
                          }
                        }
                      `,
                      { ...condition }
                    );
                    ctx.apply('loadingModel.clearLoading', 'fetchData');
                    return resData;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [account_id]
              );
              const billing = _.get(data, 'b2b_account_billing.0');
              return billing;
            }),
          }
        }
      ]
    ]
  },
  component: {
    rules: [
      [
        'data',
        {
          data: {
            currentLanguage: hook((ctx) => {
              const route = useRoute();
              const pageContext = route.getPageContext();
              const lang = _.get(pageContext, 'lang', 'de');
              return lang;
            }),
            form: hook((ctx) => {
              const data = ctx.get('billing.information');
              const user_id = ctx.apply('currentUserModel.getUserId');
              const account_id = ctx.apply('accountModel.getAccountId');
              const initialValues = () => {
                const rtn = {
                  first_name: _.get(data, 'first_name', ''),
                  last_name: _.get(data, 'last_name', ''),
                  address1: _.get(data, 'address1', ''),
                  address2: _.get(data, 'address2', ''),
                  city: _.get(data, 'city', ''),
                  zipcode: _.get(data, 'zipcode', ''),
                  country: _.get(data, 'country', ''),
                  state: _.get(data, 'state', ''),
                };
                return rtn;
              };
              const form = useFormik({
                initialValues: initialValues(),
                enableReinitialize: true,

                onSubmit: async (values, actions) => {
                  // console.log('values', values);
                  // return;
                  try {
                    const info_payload = {
                      information: values,
                      account_id,
                    };

                    const client = await getClient();
                    const res = await client.request(
                      gql`
                        mutation insert_b2b_account_billing_one($payload: b2b_account_billing_insert_input!) {
                          insert_b2b_account_billing_one(
                            object: $payload
                            on_conflict: {
                              constraint: account_billing_account_id_key,
                              update_columns: [information],
                            }
                          ) {
                            id
                          }
                        }
                      `,
                      {
                        payload: info_payload,
                      }
                    );

                    if (res) {
                      notification.success({
                        placement: 'bottomRight',
                        message: ctx.apply('i18n.rt', 'Updated'),
                      });
                    }
                  } catch (error) {
                    console.log(error);
                    if (['validation-failed'].includes(error.code)) {
                      form.setFieldError('first_name', ctx.apply('i18n.rt', 'Error'));
                    }
                    // actions.setStatus({
                    //   error,
                    // });
                  }
                },
                validationSchema: Yup.object().shape({}),
              });

              _.assign(form, {
                canSubmit: form.dirty && form.isValid,
                selectOptions: {
                  country: (() => {
                    const countries = _.sortBy(countryList.getData(), (o) => _.deburr(o.name));
                    return _.map(countries, (item) => {
                      return {
                        id: item.code,
                        text: item.name,
                      };
                    });
                  })(),
                },
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
