import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Row, Col, Card } from 'antd';
import _ from 'lodash';
import LoadingCheckWrapper from '@uz/unitz-components-web/LoadingCheckWrapper';
import gstyles from '@vl/gstyles';
import Link from '@uz/unitz-components-web/Link';
import Segment from '@uz/unitz-components-web/Segment';

const BillingStats = () => {
  return (
    <LoadingCheckWrapper>
      <DIV className="component">
        <DIV forceCtx>
          <div className="px-2 py-1">
            <Segment title={ctx.apply('i18n.rt', 'Personal Billing')}>
              <Row gutter={[8, 8]} className="mb-4">
                <Col span={8} className="">
                  <div className="bg-background1 rounded px-4 py-4 h-full">
                    <div className="text-gray-500 mb-2">
                      {ctx.apply('i18n.rt', 'Current monthly bill')}
                    </div>
                    <div className="font-bold text-lg py-2">
                      {`${ctx.apply('i18n.l', 'currency', ctx.get('billing.subscription.price') || 0)}`}
                    </div>
                  </div>
                </Col>
                <Col span={8} className="">
                  <div className="bg-background1 rounded px-4 py-4 h-full">
                    <div className="text-gray-500 mb-2">
                      {ctx.apply('i18n.rt', 'Next payment due')}
                    </div>
                    <div className="font-bold text-lg py-2">
                      {`${ctx.apply('i18n.l', 'currency', ctx.get('billing.subscription.price') || 0)}`}
                    </div>
                    <Link to={ctx.apply('routeStore.toUrl', 'toolAccountSettingsBillingPaymentHistory')}>
                      <div>{ctx.apply('i18n.rt', 'View payment history')}</div>
                    </Link>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="bg-background1 rounded px-4 py-4 h-full">
                    <div className="text-gray-500 mb-2">
                      {ctx.apply('i18n.rt', 'Payment information')}
                    </div>
                    <div className="py-2">
                      <Link to={ctx.apply('routeStore.toUrl', 'toolAccountSettingsBillingPaymentInformation')}>
                        <div>{ctx.apply('i18n.rt', 'Update payment method')}</div>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Segment>
          </div>
        </DIV>
      </DIV>
    </LoadingCheckWrapper>
  );
};

export default displayName(BillingStats);
